jQuery(document).ready(function(jQuery) {
 
  var scrolled = false;
  jQuery(window).scroll(function() {
    console.log(jQuery(window).scrollTop());
    if ((jQuery(window).scrollTop() >= 270) && (scrolled === false)) {
      jQuery('#navigation').hide();
      jQuery('#navigation').addClass('fixed');
      jQuery('#navigation').fadeIn();
      scrolled = true;
    }
    else if (jQuery(window).scrollTop() < 270) {
      scrolled = false;
      jQuery('#navigation').removeClass('fixed');
 
    }
  })
});